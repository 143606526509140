import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'



function Navbar() {

    const isLogin = localStorage.getItem("isLogin");

    return (
        <div className='container'>
            <nav class="navbar navbar-expand-lg ">
                <div class="container-fluid ">
                    <a class="navbar-brand" href="#">Navbar</a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse " id="navbarNav">
                        <ul class="navbar-nav ms-auto ">
                            <li class="nav-item p-1 " onClick={(e) => e.preventDefault()}>
                                <Link to="/" className='nav-link'>HOME</Link>
                            </li>
                            <li class="nav-item p-1">
                                <a class="nav-link" href="/results">RESULTS</a>
                            </li>
                            <li class="nav-item p-1">
                                <a class="nav-link" href="/lottery">LOTTORIES</a>
                            </li>
                            <li class="nav-item p-1">
                                <a class="nav-link ">PAGES</a>
                            </li>
                            <li class="nav-item p-1">
                                <a class="nav-link">CONTACT US</a>
                            </li>
                            {isLogin === "false" || !isLogin ? <div>
                                <Link to="/login"><button className='nvb1'>LOGIN</button></Link>
                                <Link to="/register"><button className='nvb1 ms-2'>REGISTER</button></Link></div> :

                                <div> <Link to="/profile">   <button className='nvb1'>MY PROFILE</button></Link></div>

                            }
                        </ul>
                    </div>
                </div>
            </nav></div>
    )
}

export default Navbar