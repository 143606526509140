import axios from 'axios'
import { Button } from 'bootstrap';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTickets } from '../../redux/profile.Slice';
import api from "../../conf/api.json"

let z = [];
function Ticket({ id }) {
    const API = `${api.api_base+"/api/ticket"}`;
    const API2 = `${api.api_base+"/api/tempticket"}`;
    const [data, setData] = useState();
    const [data1, setData1] = useState([]);
    const [classList, setClassList] = useState("col-sm-3 ticket disabledbutton");
    const cls = "col-sm-3 ticket";
    const currentDrawId = localStorage.getItem("d_id");

    z[currentDrawId] = [];

    data1?.map(res => {

        const a = data?.find(item => item._id === res);
        if (a?.drawId === currentDrawId) {
            z[currentDrawId].push(a?.ticketNo) 
        };
    });

    useEffect(() => {
        axios.post(API, { drawId: id }).then(res => {
            setData(res.data);

        })
    }, [selectedTicket]);



    function selectedTicket(e, res) {

        let token = localStorage.getItem("key");
        setData1([...data1, e.target.id]);
        axios.post(API2, { data: data1, _id: res }, {
            headers: {
                Bearer: token
            }
        });
        localStorage.setItem("s_t", JSON.stringify(z[currentDrawId]));
    }

    return (
        <>
            {

                data?.map(res => <button disabled={res?.disabled} id={res._id} className={res.status === true ? classList : cls} onClick={(e) => selectedTicket(e, res?._id)
                }><span>{res.ticketNo}</span></button>
                )

            }

        </>

    )
}

export default Ticket