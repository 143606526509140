import React, { useEffect, useState } from 'react'
import { Link, Navigate, redirect, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { changeStatus } from '../../redux/profile.Slice'
import axios from 'axios'
import Redirect from 'react'
import Navbar from '../../components/Navbar/Navbar'
import api from "../../conf/api.json"

function Login() {
  const API = `${api.api_base+"/api/login"}`

  const [mail, setMail] = useState()
  const [password, setPassword] = useState()


  function login(e) {

    e.preventDefault();

    axios.post(API,
      {
      email: mail,
      password: password
    }).then(res => {
      window.alert(res.data.message);
      localStorage.setItem("key", res.data.token);

      if (res.data.token === localStorage.getItem("key")){

        localStorage.setItem("isLogin", true)
        window.location.assign("/lottery")

      }



    });
  }

  return (
    <>
      <Navbar />
      <div className='d-flex justify-content-center'>
        <div className='col-sm-5 mt-5'>
          <form>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Email address</label>
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => setMail(e.target.value)} />

            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">Password</label>
              <input type="password" class="form-control" id="exampleInputPassword1" onChange={(e) => setPassword(e.target.value)} />
            </div>

            <button class="btn  btnl" onClick={(e) => login(e)}>LOGIN</button >
          </form>
          <div className='lt'><p>You don’t have an account?<a href="/register">Register Now</a></p></div>
        </div>

      </div>
    </>
  )
}

export default Login