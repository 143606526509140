import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdDelete } from 'react-icons/md'
import { getTicketId } from '../../redux/profile.Slice'
import Navbar from '../../components/Navbar/Navbar'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'
import api from "../../conf/api.json"
import CreditCard from '../../components/CreditCard/CreditCard'

function Cart() {

  const [data, setData] = useState()
  const API =  `${api.api_base+"/api/tempticket"}`;
  const API2 = `${api.api_base+"/api/ticket/update"}`;
  const API3 = `${api.api_base+"/api/user/ticket"}`;
  const API4 = `${api.api_base+"/api/user/ticket/update"}`;
  const API5 = `${api.api_base+"/api/user"}`;
  const token = localStorage.getItem("key");

  const [data1, setData1] = useState();
  let [data2, setData2] = useState();

  console.log(data2)
  async function removeItem(id, _id) {
    axios.post(API2, { id, _id });

  };




  useEffect(() => {

    axios.get(API).then(res => {
      setData(res?.data);
      setData2(data1?.[0]?.userTickets.concat(data))
      localStorage.setItem("s_t", JSON.stringify(data2));
    });
    axios.post(API5, {}, {
      headers: {
        Bearer: token
      }
    }).then(res => setData1(res.data.data));


  }, [removeItem]);

  function complatePaymanet() {

    let data1 = JSON.parse(localStorage.getItem("s_t"))
    let token = localStorage.getItem("key")
    axios.post(API4, { data: data1,pdata:data }, {
      headers: {
        Bearer: token
      }
    });
  };

  return (
    <><Navbar />
      <div className='container d-flex justify-content-center'>
        <div className='col-sm-10 shadow bg-body rounded row pcp'>
          <div className='col-sm-8 mt-4'>
            <div class="cart-table">
              <table>
                <thead>

                  <tr>

                    <th>item</th>
                    <th>quantity</th>
                    <th>ticket number</th>
                    <th>cost</th>
                    <th>discount</th>
                    <th>remove</th>

                  </tr>

                </thead>
                {
                  data?.map(res => <tbody>
                    <tr>
                      <td>Ticket</td>
                      <td>1</td>
                      <td>{res?.ticketNo}</td>
                      <td>€{1 * 0.99}</td>
                      <td>$0.00</td>
                      <td style={{ cursor: "pointer" }} onClick={() => removeItem(res?.ticketNo, res?._id)}><MdDelete /></td>
                    </tr>
                  </tbody>)
                }
              </table>
            </div>
          </div>
          <div className="col-sm-4 mt-4"><div class="cart-summary-area">
            <h3 class="summary-area-title">Cart Summary</h3>
         
            <CreditCard/>
           
              <button class="payment-btn" onClick={() => complatePaymanet()}>pay<span>(€ {data?.length * 0.99})</span></button>
           

          </div></div>
        </div>
      </div></>
  )


}

export default Cart