
import React from 'react'
import Navbar from '../../components/Navbar/Navbar';
import img1 from "../../assets/images/box.png";
import img2 from "../../assets/images/car.png";
import img3 from "../../assets/images/chart.png";
import img4 from "../../assets/images/dollars.png";
import img5 from "../../assets/images/laptop.png";
import img6 from "../../assets/images/money-2.png";
import img7 from "../../assets/images/person.png";
import img8 from "../../assets/images/person-2.png";
import img9 from "../../assets/images/power.png";


function Home() {

  return (
    <div className='container'>
      <Navbar />

      <div className='container'>

        <div className='row mt-5'>

          <div className=" col-sm-5 h123"><h1 className='title'>Take the chance to change your life</h1>
            <p className=''>Navbar is online lottery platform inspired by few navbar lover's fantasy of the ultimate lottery platfrom.</p>
            <a className='nvb1' href="/lottery"> BUY TICKET NOW</a>
          </div>
          <div className='col-sm-7'>
            <div className='bg12 banner-elements-part has_bg_image active'>

              <div class="element-one"><img src={img1} alt="vector-image" /></div>

              <div class="element-two"><img src={img2} alt="vector-image" /></div>

              <div class="element-three"><img src={img3} alt="vector-image" /></div>

              <div class="element-four"><img src={img4} alt="vector-image" /></div>

              <div class="element-five"><img src={img5} alt="vector-image" /></div>

              <div class="element-six"><img src={img6} alt="vector-image" /></div>

              <div class="element-seven"><img src={img7} alt="vector-image" /></div>

              <div class="element-eight"><img src={img8} alt="vector-image" /></div>

              <div class="element-nine"><img src={img9} alt="vector-image" /></div>
            </div>
          </div>
        </div>

      </div>

    </div>

  )
}

export default Home