
import './App.css';
import Home from './pages/home/Home';
import Login from './pages/Login/Login'
import { Route, Routes } from 'react-router-dom';
import Register from './pages/Register/Register';

import Cart from './pages/Cart/Cart';
import Profile from './pages/Profile/Profile';

import Lottery from './pages/Lottery/Lottery';
import Lott from './pages/Lottery/Lott';
import Result from './pages/Result/Results';
function App() {
  const isLogin = localStorage.getItem("isLogin")

  return (
    <div className="App">




      {isLogin === "true" ? <Routes>

        <Route path='/lott/:id' element={<Lott />} />
        <Route path='/cart' element={<Cart />} />
        <Route path="/profile" element={<Profile />} />
      </Routes> : <Routes>

        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
      </Routes>

      }


      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/lottery' element={<Lottery />} />
        <Route path='/lottery' element={<Lottery />} />
        <Route path='/results' element={<Result />} />
      </Routes>

      {isLogin === "false" & window.location.pathname === "/profile" ? <Routes>
        <Route path='/profile' element={<Login />} />
        <Route path='/lottery' element={<Login />} />
        <Route path='/cart' element={<Login />} />


      </Routes> : null}



    </div>
  );
}

export default App;
