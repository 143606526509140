import axios from 'axios'
import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import api from "../../conf/api.json"

function Register() {
  const API = `${api.api_base+"/api/register"}`;
  const [name, setName] = useState();
  const [mail, setMail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmePassword] = useState();


  function register(e) {

    e.preventDefault();

    axios.post(API, {
      name: name,
      email: mail,
      phoneNumber: phoneNumber,
      password: password,

    }).then((res) => {
      window.alert(res.data.message)
    })
  }
  return (
    <div>
      <Navbar />
      <div className='d-flex justify-content-center'>

        <div className='col-sm-5 mt-5'>
          <form onSubmit={(e) => e.preventDefault()}>
            <div class="mb-2">
              <label for="exampleInputEmail1" class="form-label">Full Name</label>
              <input type="text" class="form-control" onChange={(e) => setName(e.target.value)} />

            </div>
            <div class="mb-2">
              <label for="exampleInputEmail1" class="form-label">Email address</label>
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => setMail(e.target.value)} />

            </div>
            <div class="mb-2">
              <label class="form-label">Phone Number</label>
              <input type="number" class="form-control" onChange={(e) => setPhoneNumber(e.target.value)} />

            </div>
            <div class="mb-2">
              <label for="exampleInputPassword1" class="form-label">Password</label>
              <input type="password" class="form-control" id="exampleInputPassword1" onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div class="mb-2">
              <label class="form-label">Confirm Password</label>
              <input type="password" class="form-control" onChange={(e) => setConfirmePassword(e.target.value)} />
            </div>

            <button class="btn  btnl" onClick={(e) => register(e)}>REGISTER</button>
          </form>
          <div className='lt'><p>Already have an account?<a href="/login">Login</a></p></div>
        </div>

      </div>
    </div>
  )
}

export default Register