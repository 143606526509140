import axios from 'axios';
import React from 'react'
import { useEffect, useState } from 'react'
import api from "../../conf/api.json"

function ProfileInfo() {
  const API = `${api.api_base+"/api/user"}`;
  const token = localStorage.getItem("key");
  const [data, setData] = useState();
  const date = Intl.DateTimeFormat().resolvedOptions().timeZone;



  useEffect(() => {
    axios.get(API, {
      headers: {
        Bearer: token
      }
    }).then(res => setData(res.data.data));
  }, []);


  return (
    <div className='container'>
      {
        data?.map(res => <div className='col-sm-12 mt-3 mb-3'>
          <div className='col-sm-12 personal-detail' >
            <h6 className='p-3'> Personal Detail</h6>
            <div className='container'>
              <div className='row'>

                <div className='col-sm-6'>
                  <span>Name:</span><br />
                  <span>Date of Birth:</span><br />
                  <span>Address:</span>
                </div>

                <div className='col-sm-6'>
                  <span>{res?.name}</span> <br />
                  <span>{res?.date ? res?.date : "-- -- ----"}</span><br />
                  <span>{res?.address ? res?.address : "-------/-------"}</span>
                </div>
              </div></div>
          </div>
          <div className='col-sm-12 account-setting mt-3' ><h6 className='p-3'>Account Setting</h6>
            <div className='container'>
              <div className='row'>
                <div className='col-sm-6'>
                  <span>Language:</span><br />
                  <span>Time Zone:</span><br />
                  <span>Status:</span></div>
                <div className='col-sm-6'>
                  <span>Turkish</span> <br />
                  <span>{date}</span><br />
                  <span>Active</span></div>
              </div>
            </div>
          </div>
          <div className='col-sm-12 email-address mt-3' ><h6 className='p-3'>Email Adresses</h6>
            <div className="container">
              <div className="row">
                <div className="col-sm-6"><span>Email</span></div>
                <div className="col-sm-6"><span>{res?.email}</span></div>
              </div>
            </div>
          </div>
          <div className='col-sm-12 phone-number mt-3' ><h6 className='p-3'>Phone</h6>
            <div className="container">
              <div className="row">
                <div className="col-sm-6"><span>Mobile:</span></div>
                <div className="col-sm-6"><span>{res?.phoneNumber}</span></div>
              </div>
            </div>
          </div>
          <div className='col-sm-12 security mt-3' ><h6 className='p-3'>Security</h6>
            <div className="container">
              <div className="row">
                <div className="col-sm-6"><span>Password:</span></div>
                <div className="col-sm-6"><span>************</span></div>
              </div>
            </div>
          </div>
        </div>)
      }
    </div>
  )
}

export default ProfileInfo