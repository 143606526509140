import axios from 'axios';
import React from 'react'
import { useEffect, useState } from 'react'
import api from "../../conf/api.json"

function MyTicket() {

    const API = `${api.api_base+"/api/user"}`;
    const token = localStorage.getItem("key");
    const [data, setData] = useState();

    useEffect(() => {

        axios.get(API, {
            headers: {
                Bearer: token
            }
        }).then(res => setData(res.data.data));

    }, []);


    return (
        <div className='container'>
            <div className="col-sm-12 mt-3">
                <div className="col-sm-12 ticket-header"><h6 className='p-3'>My Tickets</h6>
                    <div className="container">
                        <div className="row d-flex justify-content-center  ts1" >{

                            data?.map(item => <div className="row">{item?.userTickets?.map(item2 =>

                                <div className='col-sm-2 bgit'><span>{item2.ticketNo}</span></div>)}</div>)
                        }
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 ticket-past-draw mt-3"><h6 className='p-3'>Past Darw</h6>
                    <div className="col-sm-12 ticket-table">
                        <table >
                            <thead>
                                <tr>
                                    <th>DRAW</th>
                                    <th>CONTEST NO</th>
                                    <th>RESULT</th>
                                    <th>STATUS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>12.12.2022</th>
                                    <th>32</th>
                                    <th>365986</th>
                                    <th>NO WIN</th>
                                </tr>
                                <tr>
                                    <th>12.12.2022</th>
                                    <th>79</th>
                                    <th>986546</th>
                                    <th>NO WIN</th>
                                </tr>
                                <tr>
                                    <th>12.12.2022</th>
                                    <th>61</th>
                                    <th>236987</th>
                                    <th>NO WIN</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default MyTicket