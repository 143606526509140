import { createSlice } from "@reduxjs/toolkit";

export const profileSlice=createSlice({
    name:"profile",
    initialState:{
        isLogin:false,
        ticketId:[],
        tickets:[]
    },
    reducers:{
        changeStatus:(state,action)=>{
            state.isLogin=action.payload
        },
        getTicketId:(state,action)=>{
            state.ticketId=action.payload;
        },
        getTickets:(state,action)=>{
            state.tickets=action.payload;
        }
    }
})


export const {changeStatus,getTicketId,getTickets}=profileSlice.actions
export default profileSlice.reducer