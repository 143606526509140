import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import axios from 'axios';
import { Link } from 'react-router-dom';
import Countdown from "react-countdown";
import api from "../../conf/api.json"

function Lottery() {

  const API = `${api.api_base+"/api/draw"}`
  const API2 = `${api.api_base+"/api/draw/update"}`
  const [data, setData] = useState();
  let a = localStorage.getItem('mainDate')
  let b = JSON.parse(a)
  let isLogin=localStorage.getItem("isLogin")

  useEffect(() => {
    axios.get(API).then(res => {
      setData(res.data);

      localStorage.setItem("mainDate", JSON.stringify(res.data));

    })
  }, []);
  
  return (
    <>
      <Navbar />
      <div className='container d-flex justify-content-center '>
        <div className='loth col-sm-7'>
          <h1>Buy Lottery Tickets Online</h1>
          <p>Buy lottery tickets online to the biggest lotteries in the world offering huge jackpot prizes that you can win when you play online lottery. Purchase official lottery tickets to the draws listed below and receive automatic result notifications and commission-free prizes when you win!</p>

        </div>

      </div>
      <div className='container '>
        <div className="col-sm-12 ">
          <div className='row'>
            { 
              b?.map((res) => {
                let timer= (Number(res?.drawDateStart) - Date.now());
                console.log(res?.display)
                 if(timer<=0){
              axios.post(API2,{_id:res?._id})
                 }
                return(<div key={res?._id} className="col-sm-4 mt-5 mb-5 ">
                <div className='drawc shadow rounded'>
                  <img src="https://media.istockphoto.com/vectors/prize-raffle-rotating-drum-with-lottery-tickets-and-lucky-winner-gift-vector-id1332163793?k=20&m=1332163793&s=612x612&w=0&h=KxUpreFS384qM30m7xBtMUeTkr9Bog3oj_OHtrQ5dAY=" alt="" /><br />
                  <span className='s1'>€156,654,654</span><br />
                  <span className='s2'>{res.drawName}</span><br />
                  <span className='s3'> Next Draw : <Countdown date={Date.now() + (Number(res?.drawDateStart) - Date.now())} /></span><br />
                <Link  to={isLogin==="true"?res.display===false?`/lott/${res?._id}`:null:"/login"}  onClick={() => localStorage.setItem("d_id", res?._id)} > <button  className="b5" disabled={res?.display}> PLAY NOW!</button></Link>

                </div>

              </div>)
              })
            }
          </div>

        </div>
      </div>
    </>
  )
}

export default Lottery