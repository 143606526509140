import React, { useEffect, useState } from 'react'
import { VscAccount } from "react-icons/vsc"
import { useDispatch } from 'react-redux'
import ProfileInfo from '../../components/Profile/ProfileInfo'
import Ticket from '../../components/MyTicket/Ticket'
import Transactions from '../../components/Transactions/Transactions'
import Favorite from '../../components/Favorite/Favorite'
import Navbar from '../../components/Navbar/Navbar'
import axios from 'axios'
import api from "../../conf/api.json"

function Profile() {
  const [data, setData] = useState();
  const token = localStorage.getItem("key");
  const API = `${api.api_base+"/api/user"}`;
  const [status, setStatus] = useState("default");

  useEffect(() => {
    axios.get(API, {
      headers: {
        Bearer: token
      }
    }).then(res => setData(res.data.data));
  }, [])


  return (
    <div className='container'>
      <Navbar />
      <div className='row'>
        <div className="col-sm-4">
          <div className='profile-pictures col-sm-9 shadow rounded mt-3'>
            <div className='col-sm-12 profile-pictures-top'></div>
            <div className='profile-pictures-circle'>
              <VscAccount className='ai' />

            </div>
            <h5 className='profile-pictures-header'>{data?.[0]?.name}</h5>
            <span>ID:{data?.[0]?._id}</span>

          </div>
          <div className='profile-menu shadow col-sm-9 rounded'>
            <ul className='navbar-nav'>
              <li className="nav-item " onClick={(e) => {
                e.preventDefault();
                setStatus("ticket");


              }}><a className='nav-link ' href=""> My Tickets</a> </li>
              <li className="nav-item " onClick={(e) => {
                e.preventDefault();
                setStatus("default")
              }}><a className='nav-link ' href="">Personal Information</a>  </li>
              {/*             <li className="nav-item " onClick={(e)=>{
                      e.preventDefault();
                      setStatus("transactions")
                    }}><a className='nav-link ' href="">Transactions</a>  </li> */}
              <li className="nav-item " onClick={(e) => {
                e.preventDefault();
                setStatus("favorite")
              }}><a className='nav-link ' href="">Favorite Lotteries</a>  </li>
              <li className="nav-item " onClick={(e) => {


                localStorage.setItem("isLogin", false)


              }}><a className='nav-link ' href="/">Log Out</a>  </li>
            </ul>

          </div>
        </div>
        <div className="col-sm-8">
          {
            status === "default" ? <ProfileInfo /> : status === "ticket" ? <Ticket /> : status === "transactions" ? <Transactions /> : status === "favorite" ? <Favorite /> : null
          }
        </div>
      </div>
    </div>
  )
}

export default Profile