import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import axios from 'axios';
import { Link, resolvePath } from 'react-router-dom';
import Countdown from "react-countdown";
import api from "../../conf/api.json"

function Result() {

  const API = `${api.api_base+"/api/draw"}`
  const [data, setData] = useState();
  const [time, setTime] = useState();

  let a = localStorage.getItem('mainDate')
  let b = JSON.parse(a)
 



  useEffect(() => {
    axios.get(API).then(res => {
      setData(res.data);

      localStorage.setItem("mainDate", JSON.stringify(res.data));
      b?.map(res=>setTime(res?.drawDateEnd))

    })
  }, []);
  
  return (
    <>
      <Navbar />
      <div className='container d-flex justify-content-center  mt-5'>
        <div className='loth col-sm-7'>
          <h1>Latest Lottery Results</h1>
          <p>Check the current lotto results and to see if you have won! All of the results presented on our website are the official results of the last draw of a particular lottery. We advise you to check lotto numbers from your ticket and compare them with the numbers drawn in a particular lottery draw.</p>

        </div>

      </div>
      <div className='container'>
        <div className="col-sm-12">
          <div className='row'>
            {
             b?.map((res) =>{
                let timer= (Number(res?.drawDateEnd) - Date.now());
                return(   <div key={res._id} className="col-sm-4 mt-5 ">
                <div className='drawc shadow rounded'>
                  <img src="https://media.istockphoto.com/vectors/prize-raffle-rotating-drum-with-lottery-tickets-and-lucky-winner-gift-vector-id1332163793?k=20&m=1332163793&s=612x612&w=0&h=KxUpreFS384qM30m7xBtMUeTkr9Bog3oj_OHtrQ5dAY=" alt="" /><br />
                  <span className='s1'>winner Ticket:</span><br />
           
                  <div className=' bgit1 '><span>{timer>0?null:res?.result?.ticketNo}</span></div>
                  <span className='s2'>{res.drawName}</span><br />
                  <span className='s3'> Show result : <Countdown date={Date.now()+(Number(res?.drawDateEnd) - Date.now())}/></span><br />
                  <Link className='b5' to={`/lottery`} onClick={() => localStorage.setItem("d_id", res?._id)} >PLAY NOW!</Link>

                </div>

              </div>)
             
              })
            }
          </div>

        </div>
      </div>
    </>
  )
}

export default Result