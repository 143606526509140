import React from 'react'

function Favorite() {
  return (
    <div className='container'>
        <div className="col-sm-12 favorite-header mt-3"><h6 className='p-3'>Upcoming Draw</h6></div>
    </div>
  )
}

export default Favorite