import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Countdown from "react-countdown";
import { useParams } from 'react-router-dom'
import Navbar from '../../components/Navbar/Navbar'
import Ticket from '../../components/Ticket/Ticket'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import api from "../../conf/api.json"


function Lott() {
  const { id } = useParams();
  const API = `${api.api_base+"/api/draw/dr"}`;
  const [data, setData] = useState();



  useEffect(() => {

    axios.post(API, { _id: id }).then(res => {

      setData(res.data); 
      localStorage.setItem("date", res?.data?.drawDateStart)

    }

    );

  }, [data])



  return (
    <><Navbar />
      <div className='container'>
        <div className="row shadow">
          <div className="col-sm-4 mt-3">
            <div className='lotdh'>
              <div className="row">
                <div className="col-sm-4"><img src="https://media.istockphoto.com/vectors/prize-raffle-rotating-drum-with-lottery-tickets-and-lucky-winner-gift-vector-id1332163793?k=20&m=1332163793&s=612x612&w=0&h=KxUpreFS384qM30m7xBtMUeTkr9Bog3oj_OHtrQ5dAY=" alt="" /></div>
                <div className="col-sm-8"><span className='s11'>{data?.drawName}</span><br /><span className='sp12'>money</span> </div>
              </div>

            </div>


          </div>
          <div className="col-sm-8 textld mt-3"><span>draw in:<Countdown date={Date.now() + (Number(localStorage.getItem("date")) - Date.now())} /></span></div>
        </div>

        <div className='row d-flex justify-content-center ticketlt'>
          <Ticket id={id} />

        </div>
        <div className='col-sm-3'><Link to="/cart" className='btn btnl mt-5' > Go To Cart</Link></div>
      </div>

    </>
  )
}

export default Lott